@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}